import React, { useState } from "react";

import { useTranslation } from "react-i18next";
import Fade from 'react-reveal/Fade'

import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';

import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css';

import swiper_img from '../../img/Home/swiper_img.png'

function StartTradeSwiper () {

    const { t } = useTranslation();

    const [swiper, setSwiper] = useState({});

    const [currentIndex, setCurrentIndex] = useState(0);

    const updateIndex = (swiperInstance) => {
        setCurrentIndex(swiperInstance.realIndex);
    };
 
    return(
        <div className="mt-[80px] xl:mt-[200px] overflow-hidden relative">
            <div className="max-w-[1280px] xl:mx-auto mx-[20px] flex flex-col xl:flex-row">              
                <div className="hidden xl:block">
                    <Fade left>
                        <img src={swiper_img} alt=""/>
                    </Fade>
                </div>
                <div className="xl:ml-[40px] xl:w-[780px] pb-[100px]">
                    <p className="text-[#2F5D52] font-[700] text-[25px] xl:text-[30px] open-sans max-w-[533px] xl:text-left text-center mx-auto xl:mx-0">{t('startswiper.1')}</p>
                    <p className="text-[#232323] text-[14px] xl:text-[16px] open-sans mt-[20px] xl:mt-[30px] xl:text-left text-center">{t('startswiper.2')}</p>
                    <div className='flex items-center gap-[22px] absolute right-[20px] bottom-0 xl:top-[380px] xl:static xl:mt-[100px] justify-end'>
                        <div className="cursor-pointer button_swiper hover:bg-[#FA6640] transition-all" onClick={() => swiper.slidePrev()}>
                            <svg className="icon" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <path d="M19 12H5" stroke="#232323" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M12 5L5 12L12 19" stroke="#232323" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                        </div>
                        <p className="text-[#424242] text-[20px] xl:text-[30px] roboto">{`0${currentIndex + 1}/04`}</p>
                        <div className="cursor-pointer button_swiper hover:bg-[#FA6640] transition-all" onClick={() => swiper.slideNext()}>
                            <svg className="icon" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <path d="M5 12H19" stroke="#232323" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M12 5L19 12L12 19" stroke="#232323" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                        </div>
                    </div>
                    <Swiper
                        modules={[Navigation]}
                        slidesPerView={1}
                        loop={true}
                        onInit={(e) => {
                            setSwiper(e);
                            setCurrentIndex(e.realIndex);
                        }}
                        onSlideChange={(e) => updateIndex(e)}
                        breakpoints={{
                            300: {
                                slidesPerView: 1,
                            },
                            700: {
                                slidesPerView: 2.2,
                            },
                            1000: {
                                slidesPerView: 3,
                            },
                            1280: {
                                slidesPerView: 2.5,
                            }
                        }}
                        className="mt-[30px]"
                    >
                        <SwiperSlide>
                            <div className="xl:w-[279px] h-[215px] rounded-[20px] bg-[#EFECE7] flex flex-col justify-center">
                                <p className="font-[600] open-sans text-[#232323] text-[16px] mx-[25px]">{t('startswiper.3')}</p>
                                <p className="text-[#232323] open-sans text-[16px] mx-[25px] mt-[20px]">{t('startswiper.4')}</p>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="xl:w-[279px] h-[215px] rounded-[20px] bg-[#FA6640] flex flex-col justify-center ">
                                <p className="font-[600] open-sans text-[#FFF] text-[16px] mx-[25px]">{t('startswiper.5')}</p>
                                <p className="text-[#FFF] open-sans text-[16px] mx-[25px] mt-[20px]">{t('startswiper.6')}</p>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="xl:w-[279px] h-[215px] rounded-[20px] bg-[#EFECE7] flex flex-col justify-center">
                                <p className="font-[600] open-sans text-[#232323] text-[16px] mx-[25px]">{t('startswiper.7')}</p>
                                <p className="text-[#232323] open-sans text-[16px] mx-[25px] mt-[20px]">{t('startswiper.8')}</p>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="xl:w-[279px] h-[215px] rounded-[20px] bg-[#2F5D52] flex flex-col justify-center">
                                <p className="font-[600] open-sans text-[#FFF] text-[16px] mx-[25px]">{t('startswiper.9')}</p>
                                <p className="text-[#FFF] open-sans text-[16px] mx-[25px] mt-[20px]">{t('startswiper.10')}</p>
                            </div>
                        </SwiperSlide>
                    </Swiper>
                </div>
            </div>
        </div>
    );
}

export default StartTradeSwiper;