import React, { useEffect, useState } from "react";
import $ from 'jquery';

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import { images } from "./imagesToLoad";

import Header from './components/Header'
import Footer from './components/Footer'

import Home from './pages/Home'
import Markets from "./pages/Markets";
import Oil from "./pages/Oil";
import CFD from "./pages/CFD";
import GoldMetals from "./pages/GoldMetals";
import Promotions from "./pages/Promotions";
import AccountTypes from "./pages/AccountTypes";
import ContactUs from "./pages/ContactUs";
import AboutUs from "./pages/AboutUs";
import TradingCrypto from "./pages/TradingCrypto";


function App() {
  const [ isLoading, setIsLoading ] = useState(true);

  const loadImage = (image) => {
    return new Promise((resolve, reject) => {
      const newImage = new Image();
      newImage.src = image;
      newImage.onload = () => {
        resolve(image);
      }
      newImage.onerror = (err) => reject(err);
    });
  };

  useEffect(() => {
    isLoading && $('body').addClass("ovf-hidden");
    Promise
      .all(images.map((image) => loadImage(image)))
      .then(() => setIsLoading(false))
      .catch((err) => {
        console.log("Failed to load images", err)
        setIsLoading(false);
      });
    // eslint-disable-next-line  
  }, []);

  useEffect(() => {
    !isLoading && $('body').removeClass("ovf-hidden");
    !isLoading && $('#loader').addClass("hide");
  }, [isLoading]); 
  return (
    <Router>
      <Header/>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/markets" element={<Markets />} />
          <Route path="/oil" element={<Oil />} />
          <Route path="/cfd" element={<CFD />} />
          <Route path="/goldmetals" element={<GoldMetals />} />
          <Route path="/promotions" element={<Promotions />} />
          <Route path="/accounttypes" element={<AccountTypes />} />
          <Route path="/contactus" element={<ContactUs />} />
          <Route path="/aboutus" element={<AboutUs />} />
          <Route path="/crypto" element={<TradingCrypto />} />
        </Routes>
      <Footer/>
    </Router>
  );
}

export default App;