import React from "react";

import { useTranslation } from "react-i18next";

import w1 from '../../img/Oil/why3.png'
import w2 from '../../img/Oil/why1.png'
import w3 from '../../img/Oil/why2.png'

function WhyIsOil () {

    const { t } = useTranslation();
 
    return(
        <div className="pt-[80px] xl:pt-[200px] relative overflow-hidden pb-[80px] xl:pb-[200px]">
            <div className="max-w-[1280px] relative xl:mx-auto mx-[20px] flex flex-col items-center">
                <p className="open-sans text-[#2F5D52] text-[20px] xl:text-[35px] font-[700]">{t('oil.5')}</p>
                <div className="grid grid-cols-1 md:grid-cols-3 w-full mt-[40px] xl:mt-[75px]">
                    <div className="flex flex-col items-center">
                        <img src={w1} alt="" />
                        <p className="font-[600] text-[14px] xl:text-[16px] text-[#232323] open-sans mt-[30px]">{t('oil.6')}</p>
                        <p className="font-[400] text-[14px] xl:text-[16px] text-[#232323] open-sans mt-[15px] max-w-[232px] text-center">{t('oil.7')}</p>
                    </div>
                    <div className="flex flex-col items-center">
                        <img src={w2} alt="" />
                        <p className="font-[600] text-[14px] xl:text-[16px] text-[#232323] open-sans mt-[30px]">{t('oil.8')}</p>
                        <p className="font-[400] text-[14px] xl:text-[16px] text-[#232323] open-sans mt-[15px] max-w-[288px] text-center">{t('oil.9')}</p>
                    </div>
                    <div className="flex flex-col items-center">
                        <img src={w3} alt="" />
                        <p className="font-[600] text-[14px] xl:text-[16px] text-[#232323] open-sans mt-[30px]">{t('oil.10')}</p>
                        <p className="font-[400] text-[14px] xl:text-[16px] text-[#232323] open-sans mt-[15px] max-w-[289px] text-center">{t('oil.11')}</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default WhyIsOil;