import React, { useState, useEffect } from "react";
import $ from 'jquery';

import { useTranslation } from "react-i18next";
import Marquee from "react-fast-marquee";
import Fade from 'react-reveal/Fade'

import swiper1 from '../../img/Home/swiper1.png'
import swiper2 from '../../img/Home/swiper2.png'
import swiper3 from '../../img/Home/swiper3.png'

import switcher1 from '../../img/Home/switcher1.png'
import switcher2 from '../../img/Home/switcher2.png'

import svg1 from '../../img/Home/icon1.svg'
import svg2 from '../../img/Home/icon2.svg'
import svg3 from '../../img/Home/icon3.svg'
import svg4 from '../../img/Home/icon4.svg'

function Trade () {

    const resizeTitle = () => {
        const bodyWidth = document.documentElement.clientWidth;

        if (bodyWidth < 800) {
            var containerWidth = $('#gatesContainer').width();
            var fontSize = containerWidth / ($('#gatesTitleResize').text().length * 0.65);
            $('#gatesTitleResize').css('font-size', fontSize,'!important');
        }
    };

    useEffect(() => {
        resizeTitle();
        $(window).resize(function () {
            resizeTitle();
        });
    }, []);

    const { t } = useTranslation();

    const [switcher, setSwicther] = useState(0);
 
    return(
        <>
            <div className="relative overflow-hidden max-w-[1280px] mx-[20px] xl:mx-auto">
                <div className="flex flex-col md:flex-row" id="gatesContainer">
                    <div>
                        <p id="gatesTitleResize" className="roboto uppercase text-[#2F5D52] text-[192.568px] font-[900]">{t('trade.1')}</p>
                    </div>
                    <div className="mt-[-30px] md:mt-[80px] xl:mt-[60px] md:ml-[20px]">
                        <p className="roboto text-[20px] xl:text-[30px] text-[#2F5D52]">{t('trade.2')}</p>
                        <p className="roboto text-[20px] xl:text-[30px] text-[#2F5D52]">{t('trade.3')}</p>
                        <p className="roboto text-[20px] xl:text-[30px] text-[#232323]">{t('trade.4')}</p>
                    </div>
                </div>
            </div>
            <Marquee autoFill pauseOnHover className="md:mt-[-40px]">
                <div className="flex flex-col items-end">
                    <img src={swiper1} alt="" />
                    <p className="opacity-[0.5] tracking-[3.5px] roboto">{t('trade.5')}</p>
                </div>
                <div className="flex flex-col items-end">
                    <img src={swiper2} alt="" />
                    <p className="opacity-[0.5] tracking-[3.5px] roboto text-[#439A50] font-[600]">{t('trade.6')}</p>
                </div>
                <div className="flex flex-col items-end">
                    <img src={swiper3} alt="" />
                    <p className="opacity-[0.5] tracking-[3.5px] roboto">{t('trade.7')}</p>
                </div>
            </Marquee>
            <div className="max-w-[1280px] mx-[20px] xl:mx-auto mt-[60px] xl:mt-[200px]">
                {switcher === 0 ? (
                <div className="flex flex-col xl:flex-row justify-between">
                    <Fade>
                        <div className="hidden xl:block w-[525px] h-[450px] rounded-[15px] overflow-hidden">
                            <img src={switcher1} alt="" />
                        </div>
                    </Fade>
                    <div className="mt-[50px] max-w-[525px]">
                        <div className="flex w-[202px] h-[56px] rounded-[15px] bg-[#EFECE7] items-center justify-between">
                            <Fade><div onClick={() => setSwicther(0)} className="cursor-pointer ml-[5px] w-[91px] h-[48px] bg-[#2F5D52] rounded-[15px] flex items-center justify-center">
                                <p className="text-white roboto text-[14px] xl:text-[16px]">{t('trade.7')}</p>
                            </div></Fade>
                            <Fade><div onClick={() => setSwicther(1)} className="cursor-pointer mr-[5px] w-[91px] h-[48px] bg-transparent rounded-[15px] flex items-center justify-center">
                                <p className="text-[#424242] roboto text-[14px] xl:text-[16px]">{t('trade.8')}</p>
                            </div></Fade>
                        </div>
                        <p className="text-[#2F5D52] roboto text-[20px] xl:text-[30px] font-[700] mt-[30px] xl:mt-[50px]">{t('trade.9')}</p>
                        <p className="roboto text-[14px] xl:text-[23px] text-[#232323] mt-[15px] xl:mt-[30px]">{t('trade.10')}</p>
                    </div>
                </div>
                ) : (<></>)}
                {switcher === 1 ? (
                <div className="flex flex-col xl:flex-row justify-between">
                    <Fade>
                        <div className="hidden xl:block w-[525px] h-[450px] rounded-[15px] overflow-hidden">
                            <img src={switcher2} alt="" />
                        </div>
                    </Fade>
                    <div className="mt-[50px] max-w-[525px]">
                        <div className="flex w-[202px] h-[56px] rounded-[15px] bg-[#EFECE7] items-center justify-between">
                            <Fade><div onClick={() => setSwicther(0)} className="cursor-pointer ml-[5px] w-[91px] h-[48px] bg-transparent rounded-[15px] flex items-center justify-center">
                                <p className="text-[#424242] roboto text-[14px] xl:text-[16px]">{t('trade.7')}</p>
                            </div></Fade>
                            <Fade><div onClick={() => setSwicther(1)} className="cursor-pointer mr-[5px] w-[91px] h-[48px] bg-[#2F5D52] rounded-[15px] flex items-center justify-center">
                                <p className="text-white roboto text-[14px] xl:text-[16px]">{t('trade.8')}</p>
                            </div></Fade>
                        </div>
                        <p className="text-[#2F5D52] roboto text-[20px] xl:text-[30px] font-[700] mt-[30px] xl:mt-[50px]">{t('trade.11')}</p>
                        <p className="roboto text-[14px] xl:text-[23px] text-[#232323] mt-[15px] xl:mt-[30px]">{t('trade.12')}</p>
                    </div>
                </div>
                ) : (<></>)}
                <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 mt-[80px] xl:mt-[200px] gap-[20px] xl:gap-0">
                    <Fade>
                        <div className="flex flex-col items-center">
                            <img src={svg1} alt="" />
                            <p className="max-w-[220px] text-center text-[#232323] open-sans mt-[20px]">{t('trade.13')}</p>  
                        </div>
                        <div className="flex flex-col items-center">
                            <img src={svg2} alt="" />
                            <p className="max-w-[220px] text-center text-[#232323] open-sans mt-[20px]">{t('trade.14')}</p>  
                        </div>
                        <div className="flex flex-col items-center">
                            <img src={svg3} alt="" />
                            <p className="max-w-[220px] text-center text-[#232323] open-sans mt-[20px]">{t('trade.15')}</p>  
                        </div>
                        <div className="flex flex-col items-center">
                            <img src={svg4} alt="" />
                            <p className="max-w-[220px] text-center text-[#232323] open-sans mt-[20px]">{t('trade.16')}</p>  
                        </div>
                    </Fade>
                </div>
            </div>
        </>
    );
}

export default Trade;