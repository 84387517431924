import React, { useEffect } from "react";

import Main from '../components/GoldMetals/Main'
import WhyShould from "../components/GoldMetals/WhyShould";

function GoldMetals () {
 
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
      
    return(
        <div>
            <Main/>
            <WhyShould/>
        </div>
    );
}

export default GoldMetals;