import React, { useEffect } from "react";

import Main from '../components/AccountTypes/Main'
import ComparePick from "../components/AccountTypes/ComparePick";
import WhyWould from "../components/AccountTypes/WhyWould";

function AccountTypes () {
 
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
      
    return(
        <div>
            <Main/>
            <ComparePick/>
            <WhyWould/>
        </div>
    );
}

export default AccountTypes;