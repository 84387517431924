import React, { useEffect } from "react";

import Main from '../components/Home/Main'
import Trade from "../components/Home/Trade";
import Start from "../components/Home/Start";
import UseTrade from "../components/Home/UseTrade";
import OurRewards from "../components/Home/OurRewards";

function Home () {
 
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
      
    return(
        <div>
            <Main/>
            <Trade/>
            <Start/>
            <UseTrade/>
            <OurRewards/>
        </div>
    );
}

export default Home;