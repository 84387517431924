import React, { useEffect } from "react";

import Main from '../components/AboutUs/Main'
import VisionMision from "../components/AboutUs/VisionMision";
import WithYourSupport from "../components/AboutUs/WithYourSupport";
import OurRewards from "../components/Home/OurRewards";

function AboutUs () {
 
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
      
    return(
        <div>
            <Main/>
            <VisionMision/>
            <WithYourSupport/>
            <OurRewards/>
        </div>
    );
}

export default AboutUs;