import React, { useEffect } from "react";

import Main from '../components/Markets/Main'
import Forex from "../components/Markets/Forex";
import TradeStocks from "../components/Markets/TradeStocks";
import Work from "../components/Markets/Work";

function Markets () {
 
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
      
    return(
        <div>
            <Main/>
            <Forex/>
            <TradeStocks/>
            <Work/>
        </div>
    );
}

export default Markets;