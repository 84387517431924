import React from "react";

import { useTranslation } from "react-i18next";

import r1 from '../../img/Home/rewards1.png'
import r2 from '../../img/Home/rewards2.png'
import r3 from '../../img/Home/rewards3.png'
import r4 from '../../img/Home/rewards4.png'
import r5 from '../../img/Home/rewards5.png'

function OurRewards () {

    const { t } = useTranslation();
 
    return(
        <div className="mt-[80px] xl:mt-[200px] pb-[80px] xl:pb-[200px] relative overflow-hidden">
            <div className="max-w-[1280px] relative xl:mx-auto mx-[20px] flex flex-col">
                <p className="text-[#2F5D52] font-[700] text-[25px] xl:text-[35px] open-sans max-w-[533px] mx-auto">{t('ourrewards')}</p>
                <div className="flex flex-col xl:flex-row mx-auto gap-[50px] mt-[20px]">
                    <div className="grid grid-cols-3 justify-items-center gap-[50px] opacity-[0.5]">
                        <img src={r1} alt="" />
                        <img src={r2} alt="" />
                        <img src={r3} alt="" />
                    </div>
                    <div className="grid grid-cols-2 justify-items-center gap-[50px] opacity-[0.5]">
                        <img src={r4} alt="" />
                        <img src={r5} alt="" />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default OurRewards;