import React, { useEffect } from "react";

import Main from '../components/Oil/Main'
import WhyIsOil from "../components/Oil/WhyIsOil";

function Oil () {
 
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
      
    return(
        <div>
            <Main/>
            <WhyIsOil/>
        </div>
    );
}

export default Oil;