import React from "react";

import { useTranslation } from "react-i18next";

import w1 from '../../img/Promotions/bonus1.png'
import w2 from '../../img/Promotions/bonus2.png'
import w3 from '../../img/Promotions/bonus3.png'

import bonus from '../../img/Promotions/bonusmain.png'

function ClaimBonus () {

    const { t } = useTranslation();
 
    return(
        <div className="pt-[80px] xl:pt-[200px] relative overflow-hidden">
            <div className="max-w-[1280px] relative xl:mx-auto mx-[20px] flex flex-col items-center">
                <p className="open-sans text-[#2F5D52] text-[20px] xl:text-[35px] font-[700]">{t('promotions.17')}</p>
                <div className="grid grid-cols-1 md:grid-cols-3 w-full mt-[40px] xl:mt-[75px]">
                    <div className="flex flex-col items-center">
                        <img src={w1} alt="" />
                        <p className="font-[600] text-[14px] xl:text-[16px] text-[#232323] open-sans mt-[30px]">{t('promotions.18')}</p>
                        <p className="font-[400] text-[14px] xl:text-[16px] text-[#232323] open-sans mt-[15px] max-w-[232px] text-center">{t('promotions.19')}</p>
                    </div>
                    <div className="flex flex-col items-center">
                        <img src={w2} alt="" />
                        <p className="font-[600] text-[14px] xl:text-[16px] text-[#232323] open-sans mt-[30px]">{t('promotions.20')}</p>
                        <p className="font-[400] text-[14px] xl:text-[16px] text-[#232323] open-sans mt-[15px] max-w-[288px] text-center">{t('promotions.21')}</p>
                    </div>
                    <div className="flex flex-col items-center">
                        <img src={w3} alt="" />
                        <p className="font-[600] text-[14px] xl:text-[16px] text-[#232323] open-sans mt-[30px] text-center">{t('promotions.22')}</p>
                        <p className="font-[400] text-[14px] xl:text-[16px] text-[#232323] open-sans mt-[15px] max-w-[289px] text-center">{t('promotions.23')}</p>
                    </div>
                </div>
            </div>
            <div className="max-w-[1280px] mx-[20px] xl:mx-auto pt-[80px] xl:pt-[200px] pb-[80px] xl:pb-[200px]">
                <div className="flex flex-col xl:flex-row justify-between">
                    <div className="relative  hidden xl:flex">
                        <img src={bonus} alt="" />
                        <p className="absolute text-[#2F5D52] open-sans text-[20px] xl:text-[30px] max-w-[317px] font-[700] left-[70px] bottom-[20px]">{t('promotions.24')}</p>
                        <div className="my-[35px] ml-[-50px] flex flex-col justify-between">
                            <div className="w-[93px] h-[93px] rounded-full bg-[#2F5D52] flex items-center justify-center">
                                <p className="text-white open-sans text-[35px] font-[700]">1</p>
                            </div>
                            <div className="w-[93px] h-[93px] rounded-full bg-[#2F5D52] flex items-center justify-center">
                                <p className="text-white open-sans text-[35px] font-[700]">2</p>
                            </div>
                            <div className="w-[93px] h-[93px] rounded-full bg-[#2F5D52] flex items-center justify-center">
                                <p className="text-white open-sans text-[35px] font-[700]">3</p>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-col justify-between">
                        <div>
                            <p className="text-[16px] xl:text-[20px] text-[#232323] font-[700]">
                                {t('promotions.25')}
                            </p>
                            <p className="max-w-[525px] mt-[15px] open-sans text-[#232323]">
                                {t('promotions.26')}
                            </p>
                        </div>
                        <div className="mt-[40px] xl:mt-0">
                            <p className="text-[16px] xl:text-[20px] text-[#232323] font-[700]">
                                {t('promotions.27')}
                            </p>
                            <p className="max-w-[525px] mt-[15px] open-sans text-[#232323]">
                                {t('promotions.28')}
                            </p>
                        </div>
                        <div className="mt-[40px] xl:mt-0">
                            <p className="text-[16px] xl:text-[20px] text-[#232323] font-[700]">
                                {t('promotions.29')}
                            </p>
                            <p className="max-w-[525px] mt-[15px] open-sans text-[#232323]">
                                {t('promotions.30')}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ClaimBonus;