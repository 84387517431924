import React, { useEffect } from "react";

import Main from '../components/TradingCrypto/Main'
import WhyShould from "../components/TradingCrypto/WhyShould";

function TradingCrypto () {

    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
      
    return(
        <div>
            <Main/>
            <WhyShould/>
        </div>
    );
}

export default TradingCrypto;