import React from "react";

import { useTranslation } from "react-i18next";
import Marquee from "react-fast-marquee";

import m1 from '../../img/Markets/marque6.png'
import m2 from '../../img/Markets/marque5.png'
import m3 from '../../img/Markets/marque4.png'
import m4 from '../../img/Markets/marque3.png'
import m5 from '../../img/Markets/marque2.png'
import m6 from '../../img/Markets/marque1.png'

function Work () {

    const { t } = useTranslation();
 
    return(
        <>
            <div className="max-w-[1280px] mx-[20px] xl:mx-auto mt-[80px] xl:mt-[200px] flex flex-col">
                <p className="open-sans text-[#2F5D52] text-[20px] xl:text-[35px] font-[700] mx-auto">{t('market.15')}</p>
                <p className="text-[14px] xl:text-[16px] roboto text-[#232323] mt-[15px] mx-auto">{t('market.16')}</p>
            </div>
            <Marquee autoFill pauseOnHover className="mt-[30px] xl:mt-[80px] pb-[80px] xl:pb-[180px]">
                <img src={m1} alt="" className="mr-[60px] xl:mr-[120px] h-[20px] xl:h-[40px]"/>
                <img src={m2} alt="" className="mr-[60px] xl:mr-[120px] h-[20px] xl:h-[40px]"/>
                <img src={m3} alt="" className="mr-[60px] xl:mr-[120px] h-[20px] xl:h-[40px]"/>
                <img src={m4} alt="" className="mr-[60px] xl:mr-[120px] h-[20px] xl:h-[40px]"/>
                <img src={m5} alt="" className="mr-[60px] xl:mr-[120px] h-[20px] xl:h-[40px]"/>
                <img src={m6} alt="" className="mr-[60px] xl:mr-[120px] h-[20px] xl:h-[40px]"/>
            </Marquee>
        </>
    );
}

export default Work;