import React, { useState } from "react";

import { useTranslation } from "react-i18next";

import wallet from '../../img/AboutUs/wallet.png'

import { Link } from "react-router-dom"

const faqData = [
    {
        question: '2024',
        answer: 'aboutus.10',
    },
    {
        question: '2023',
        answer: 'aboutus.11',
    },
    {
        question: '2022',
        answer: 'aboutus.12',
    },
    {
        question: '2021',
        answer: 'aboutus.13',
    },
    {
        question: '2020',
        answer: 'aboutus.14',
    },
    {
        question: '2019',
        answer: 'aboutus.15',
    }
]

function WithYourSupport () {

    const { t } = useTranslation();

    const [openIndex, setOpenIndex] = useState(0);

    const toggleText = (index) => {
        setOpenIndex((prevIndex) => (prevIndex === index ? null : index));
    };
 
    return(
        <div className="pt-[80px] xl:pt-[200px] relative max-w-[1280px] mx-[20px] xl:mx-auto">
            <div className="flex flex-col xl:flex-row justify-between">
                <div>
                    <p className="max-w-[446px] open-sans text-[22px] xl:text-[35px] open-sans text-[#2F5D52] font-[700]">{t('aboutus.9')}</p>
                    <div className="cursor-pointer button2 !bg-[#232323] flex mt-[40px] xl:mt-[50px]">
                        <p className="text-white open-sans text-[14px] xl:text-[16px] font-[700]">{t('main.2')}</p>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path d="M5 12H19" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M12 5L19 12L12 19" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </div>
                    <img src={wallet} alt="" className="hidden xl:block mt-[90px]"/>
                </div>
                <div>
                    {faqData.map((faq, index) => (
                        <div key={index}>
                            <div className={`xl:w-[600px] flex justify-between mt-[20px] h-[60px] transition-all duration-300 ${openIndex === index ? 'h-auto bg-[#EFECE7]' : 'bg-[#EFECE7]'}`}>
                                <div>
                                    <div className="flex">
                                        <p className='mt-[15px] open-sans font-[700] text-[14px] xl:text-[16px] text-[#232323] ml-[40px]'>{faq.question}</p>
                                    </div>
                                    {openIndex === index && (
                                    <div className="flex">
                                        <p className='text-[#232323] open-sans text-[14px] xl:text-[16px] mt-[12px] pb-[20px] ml-[40px]'>{t(faq.answer)}</p>
                                    </div>
                                    )}
                                </div>
                                <div className='cursor-pointer flex mx-[20px]' onClick={() => toggleText(index)}>
                                    <svg className={`mt-[25px] ${openIndex === index ? 'transition-transform' : 'transition-transform -rotate-90'}`} xmlns="http://www.w3.org/2000/svg" width="22" height="12" viewBox="0 0 22 12" fill="none">
                                        <path d="M21.9999 0.842417L11 11.8423L1.31172e-07 0.842417L0.697128 0.145109L11 10.448L21.3028 0.145109L21.9999 0.842417Z" fill="#232323"/>
                                    </svg>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default WithYourSupport;