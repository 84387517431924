import React, { useEffect } from "react";

import Main from '../components/Promotions/Main'
import WhyShould from "../components/Promotions/WhyShould";
import ClaimBonus from "../components/Promotions/ClaimBonus";

function Promotions () {
 
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
      
    return(
        <div>
            <Main/>
            <WhyShould/>
            <ClaimBonus/>
        </div>
    );
}

export default Promotions;